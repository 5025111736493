<template>
  <div class="information">
    <el-form :model="form" ref="form" label-width="40px" class="form">
      <el-form-item class="w540">
        <editor placeholder="请输入" @html="editChange" :testcontent="form.description" height="400"></editor>
      </el-form-item>
      <div class="buttons text_c marT50">
        <el-button type="info" plain class="w120" @click="halClose()">取消</el-button>
        <el-button type="primary" class="w120" @click="handleSave('form')">保存</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import editor from "@/components/public/editor";
export default {
  name: "personInfo",
  components: {
    editor,
  },
  props: ["id","description"],
  data() {
    return {
      form: {
        description: "",
        id: "",
      },
    };
  },
  methods: {
    handleSave(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          let that = this;
          let res = await that.$api.updateMyresumeDescription(that.form);
          if (res.data.success) {
            that.$message.success(res.data.msg);
            that.halClose(0);
            that.form = {};
            that.$parent.$parent.disableResume();
          }
        } else {
          this.$message.error("信息填写不完整！");
          return false;
        }
      });
    },
    halClose(val) {
      this.$emit("close", false);
    },
    editChange(val) {
      console.log(val);
      this.form.description = val;
    },
  },
  mounted() {
    this.form.description = this.description;
    this.form.id = this.id;
  },
};
</script>

<style scoped>
</style>
