<template>
  <div class="education">
    <el-form :model="form" :rules="error" ref="form" label-width="120px" class="form">
      <el-form-item label="学校名称" required class="w540" prop="schoolName">
        <el-input v-model="form.schoolName" placeholder="请输入学校名称"></el-input>
      </el-form-item>
      <el-form-item label="是否统招" required class="w540" prop="isGeneral">
        <el-radio-group v-model="form.isGeneral">
          <el-radio label="1">统招</el-radio>
          <el-radio label="2">非统招</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="学历" class="w540" prop="edu">
        <MulSelect
            :code="'user_edu'"
            class="w420"
            :unlimitedEdu="true"
            placeholder="请选择"
            @setMValue="setSelectValue($event,'edu')"
            :defaultValue="form.edu"
        ></MulSelect>
      </el-form-item>
      <el-form-item label="就读时间" required class="width100" prop="benginTime">
        <el-date-picker
            v-model="form.benginTime"
            type="month"
            value-format="yyyy/MM"
            class="w200"
            placeholder="请选择开始时间"
            popper-class="eagle-a11y-uncut"
        ></el-date-picker>
        <span class="paddL10 paddR10">至</span>
        <el-date-picker
            v-model="form.endTime"
            type="month"
            value-format="yyyy/MM"
            class="w200"
            popper-class="eagle-a11y-uncut"
            placeholder="请选择结束时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="专业名称" class="w540">
        <el-input v-model="form.majorName" placeholder="请输入专业名称"></el-input>
      </el-form-item>
      <el-form-item label="在校经历" class="w540">
        <editor
            placeholder="请输入"
            @html="editChange"
            :testcontent="form.schoolExperience"
            height="400"
        ></editor>
      </el-form-item>
      <div class="buttons text_c marT50">
        <el-button type="info" plain class="w120" @click="halClose()">取消</el-button>
        <el-button type="primary" class="w120" @click="handleSave('form')">保存</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import MulSelect from "@/components/selectComponents/MulSelect";
import ThreeLink from "@/components/selectComponents/ThreeLink";
import editor from "@/components/public/editor";

export default {
  name: "education",
  components: {
    MulSelect,
    ThreeLink,
    editor,
  },
  props: ["id"],
  data() {
    return {
      form: {
        edu: '',
        schoolName: "",
        isGeneral: "1",
        benginTime: "",
        endTime: "",
        majorName: "",
        schoolExperience: "",
      },
      error: {
        schoolName: [
          {required: true, message: '请输入学校名称', trigger: 'blur'},
        ],
        edu: [
          {required: true, message: '请选择学历', trigger: 'change'},
        ],
        benginTime: [
          {required: true, message: '请选择就读时间', trigger: 'change'},
        ],

      },
    };
  },
  methods: {
    // 提交表单
    handleSave(form) {
      this.$refs[form].validate(async (valid) => {
        try {
          if (valid) {
            let res = await this.$api.resumeEdu(this.form);
            if (res.data.success) {
              this.$message.success(res.data.msg);
              this.$parent.$parent.disableResume();
              this.halClose();

            }
          } else {
            this.$message.error("您有必填项未填写!");
            return false;
          }
        } catch (err) {
          alert(err);
        }
      });
    },

    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    setSelectValue(val, key) {
      this.form[key] = val;
      console.log(this.form)
    },
    editChange(val) {
      this.form.schoolExperience = val;
    },
    halClose(val) {
      this.$emit("close", false);
    },
    myResumeEduDtail() {
      let id = this.id;
      this.$api.myResumeEduDtail({id: this.id}).then((res) => {
        console.log(res);
        this.form = res.data.data;
      });
    },
  },
  created() {
    if (this.id) {
      this.myResumeEduDtail();
    }
  },
};
</script>

<style scoped>
</style>
