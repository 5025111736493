<template>
  <div class="work">
    <el-form :model="form" :rules="error" ref="form" label-width="120px" class="form">
      <el-form-item label="公司名称" required class="w540" prop="companyName">
        <el-input v-model="form.companyName" placeholder="请输入公司名称"></el-input>
      </el-form-item>
      <el-form-item label="所属行业" required class="w540" prop="hyTop">
        <ThreeLink
          :codeType="'hy1'"
          :codeLevel="'2'"
          placeholder="请选择所属行业"
          class="width100"
          @setMValue="setScasValue($event,form,['hyTop','hy'])"
          :defaultValue="[form.hyTop,form.hy]"
        ></ThreeLink>
      </el-form-item>
      <el-form-item label="所属部门" class="w540" prop="department">
        <el-input v-model="form.department" placeholder="请输入部门"></el-input>
      </el-form-item>
      <el-form-item label="职业名称" class="w540" prop="jobName">
        <el-input v-model="form.jobName" placeholder="请输入职位名称"></el-input>
      </el-form-item>
      <el-form-item label="在职时间" required class="width100" prop="beginTime">
        <el-date-picker
          v-model="form.beginTime"
          type="month"
          value-format="yyyy/MM"
          class="w200"
          placeholder="请选择开始时间"
          popper-class="eagle-a11y-uncut"
        ></el-date-picker>
        <span class="paddL10 paddR10">至</span>
        <el-date-picker
          v-model="form.endTime"
          type="month"
          value-format="yyyy/MM"
          class="w200"
          placeholder="请选择结束时间"
          popper-class="eagle-a11y-uncut"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="工作内容" required class="w540" prop="jobContent">
        <editor placeholder="请输入" @html="editChange" :testcontent="form.jobContent" height="400"></editor>
      </el-form-item>
      <div class="buttons text_c marT50">
        <el-button type="info" plain class="w120" @click="halClose()">取消</el-button>
        <el-button type="primary" class="w120" @click="handleSave('form')">保存</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import MulSelect from "@/components/selectComponents/MulSelect";
import ThreeLink from "@/components/selectComponents/ThreeLink";
import editor from "@/components/public/editor";
export default {
  name: "work",
  components: {
    MulSelect,
    ThreeLink,
    editor,
  },
  props: ["id"],
  data() {
    return {
      form: {
        companyName: "",
        hyTop: "",
        hy: "",
        department: "",
        jobName: "",

        beginTime: "",
        endTime: "",
        jobContent: "",
      },
      error: {
        companyName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' },
          ],
                  hyTop: [
            { required: true, message: '请输入所属行业', trigger: 'blur' },
          ],
                  beginTime: [
            { required: true, message: '请输入在职时间', trigger: 'blur' },
          ],
                  jobContent: [
            { required: true, message: '请输入工作内容', trigger: 'blur' },
          ],
      },
    };
  },
  methods: {
    handleSave(form) {
      this.$refs[form].validate(async (valid) => {
        try {
          if (valid) {
            let res = await this.$api.resumeWork(this.form);
            if (res.data.success) {
              this.$message.success(res.data.msg);
              this.halClose(0);
              this.form = {};
              this.$parent.$parent.disableResume();
            }
          } else {
            this.$message.error('您有必填信息未填写！');
            return false;
          }
        } catch (err) {
          alert(err);
        }
      });
    },
    async myResumeWork() {
      let id = this.id;

      try {
        let res = await this.$api.myResumeWork({ id: this.id });
        console.log(res);

        this.form = res.data.data;
      } catch (error) {
        alert(error);
      }
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    editChange(val) {
      this.form.jobContent = val;
    },
    halClose(val) {
      this.$emit("close", false);
    },
  },
  created() {
    if (this.id) {
      this.myResumeWork();
    }
  },
};
</script>

<style scoped>
</style>
