<template>
  <div class="skill">
    <el-form :model="form" :rules="error" ref="form" label-width="120px" class="form">
      <el-form-item label="证书名称" prop="certName" class="w540">
        <el-input v-model="form.certName" placeholder="请输入技能名称"></el-input>
      </el-form-item>
      <el-form-item label="获得证书时间" prop="ing" class="w540">
        <el-date-picker
            v-model="form.certTime"
            type="date"
            value-format="yyyy/MM/dd"
            class="width100"
            placeholder="请选择开始时间"
            popper-class="eagle-a11y-uncut"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="上传证书" class="w540">
        <cd-upload v-model="form.imgUrl" :isLogo="true" className="avatars" tips=""
                   :on-success="handleAvatarimageurl"/>
      </el-form-item>
      <div class="buttons text_c marT50">
        <el-button type="info" plain class="w120" @click="halClose()">取消</el-button>
        <el-button type="primary" class="w120" @click="handleSave('form')">保存</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "skill",
  props: ['id'],
  data() {
    return {
      form: {
        certName: "",
        certTime: "",
        imgUrl: "",
      },
      error: {
        certName: [
          {required: true, message: "证书名称不能为空", trigger: "blur"},
        ],
        certTime: [
          {
            required: true,
            message: "获取证书时间不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    handleSave(form) {
      this.$refs[form].validate(async (valid) => {
        try {
          if (valid) {
            let res = await this.$api.resumeCert(this.form);
            if (res.data.success) {
              this.$message.success(res.data.msg);
              this.halClose();
              this.$parent.$parent.disableResume();
              this.form = {};
            }
          } else {
            this.$message.error("信息填写不完整！");
            return false;
          }
        } catch (err) {
          alert(err);
        }
      });
    },
    erroupload() {
      this.$message({
        message: "图片上传失败，请重新上传！！",
        center: true,
      });
    },
    beforeAvatarUpload(file) {
      let type = ["image/jpeg", "image/png"];
      const isImage = type.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error("上传图片格式不正确");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isImage && isLt2M;
    },
    handleAvatarimageurl(res, file) {
      console.log(res.msg);
      this.form.imgUrl = res.msg;
    },
    halClose(val) {
      this.$emit('close', false)
    },
    async myResumeCertDetail() {
      let id = this.id;
      try {
        let res = await this.$api.myResumeCertDetail({id: this.id});
        console.log(res);
        this.form = res.data.data;
      } catch (error) {
        alert(error);
      }
    },
  },
  created() {
    if (this.id) {
      this.myResumeCertDetail();
    }
  },
};
</script>

<style scoped lang="less">
/deep/ .avatar-uploader {
  position: relative;
  width: 178px;
  height: 178px;
  //border: 1px solid #ddd;
  background: #f9f9f9;
  border-radius: 6px;
  cursor: pointer;
  //overflow: hidden;
  .el-upload.el-upload--text {
    display: block !important;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .el-icon-plus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #00924c;
  }
}
</style>
