<template>
  <div class="resume_info">
    <el-form :model="form" :rules="error" ref="form" label-width="120px" class="form">
      <el-form-item label="求职状态" prop="jobStatus" required class="w540">
        <MulSelect
          :code="'job_invia'"
          class="w420"
          placeholder="请选择求职状态"
          @setMValue="setSelectValue($event,'jobStatus')"
          :defaultValue="form.jobStatus"
        ></MulSelect>
      </el-form-item>
      <el-form-item label="期望地点" prop="provinceid" required class="w540">
        <ThreeLink
          :codeType="'provinceid'"
          :codeLevel="'3'"
          placeholder="请选择期望地点"
          class="width100"
          @setMValue="setScasValue($event,form,['provinceid','cityid','threeCityid'])"
          :defaultValue="[form.provinceid,form.cityid,form.threeCityid]"
        ></ThreeLink>
      </el-form-item>
      <el-form-item label="期望薪资" prop="salary" required class="w540">
        <MulSelect
          :code="'user_salary'"
          class="w420"
          placeholder="请选择期望薪资"
          @setMValue="setSelectValue($event,'salary')"
          :defaultValue="form.salary"
        ></MulSelect>
      </el-form-item>
      <el-form-item label="工作性质" prop="jobType" required class="w540">
        <MulSelect
          :code="'job_type'"
          class="w420"
          placeholder="请选择工作性质"
          @setMValue="setSelectValue($event,'jobType')"
          :defaultValue="form.jobType"
        ></MulSelect>
      </el-form-item>
      <el-form-item label="期望行业" prop="hyTop" required class="w540">
        <ThreeLink
          :codeType="'hy1'"
          :codeLevel="'2'"
          class="w420"
          @setMValue="setScasValue($event,form,['hyTop','hy'])"
          :defaultValue="[form.hyTop,form.hy]"
        ></ThreeLink>
      </el-form-item>
      <el-form-item label="期望职位" prop="jobTop" required class="w540">
        <ThreeLink
          :codeType="'job1'"
          :codeLevel="'3'"
          placeholder="请选择期望职位"
          class="width100"
          @setMValue="setScasValue($event,form,['jobTop','jobNext','jobPost'])"
          :defaultValue="[form.jobTop,form.jobNext,form.jobPost]"
        ></ThreeLink>
      </el-form-item>
      <div class="buttons text_c marT50">
        <el-button type="info" plain class="w120" @click="halClose()">取消</el-button>
        <el-button type="primary" class="w120" @click="handleSave('form')">保存</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import MulSelect from "@/components/selectComponents/MulSelect";
import ThreeLink from "@/components/selectComponents/ThreeLink";
export default {
  name: "intention",
  components: {
    MulSelect,
    ThreeLink,
  },
  props: ["id"],
  data() {
    return {
      form: {
        jobStatus: "",
        provinceid: "",
        cityid: "",
        threeCityid: "",
        addressDetail: "",
        salary: "",
        jobType: "",
        hyTop: "",
        hyTwo: "",
        hyThree: "",
        jobTop: "",
        jobNext: "",
        jobPost: "",
      },
      error: {
        jobStatus: [
          { required: true, message: "求职状态不能为空", trigger: "change" },
        ],
        salary: [
          { required: true, message: "期望薪资不能为空", trigger: "change" },
        ],
        provinceid: [
          { required: true, message: "期望地点不能为空", trigger: "change" },
        ],
        jobType: [
          { required: true, message: "工作性质不能为空", trigger: "change" },
        ],
        hyTop: [
          { required: true, message: "期望行业不能为空", trigger: "change" },
        ],
        jobTop: [
          { required: true, message: "期望职位不能为空", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    handleSave(form) {
      let that=this
      this.$refs[form].validate(async (valid) => {
        try {
          if (valid) {
            let res = await that.$api.resumeExpect(that.form);
            if (res.data.success) {
              that.$message.success(res.data.msg);
              that.halClose(0);
               that.$parent.$parent.disableResume();
            }
          } else {
            that.$message.error("信息填写不完整！");
            return false;
          }
        } catch (err) {
          alert(err);
        }
      });
    },
    halClose(val) {
      this.$emit("close", false);
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    async myResumeExpect() {
      // let disabledId=this.id
      try {
        let res = await this.$api.myResumeExpect();
        console.log(res);
        this.form = res.data.data;
      } catch (error) {
        alert(error);
      }
    },
  },
  created() {
    if (this.id){
      this.myResumeExpect();
    }
  },
};
</script>

<style scoped lang="less">
</style>
