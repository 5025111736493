<template>
  <div class="education">
    <el-form :model="form" :rules="error" ref="form" label-width="120px" class="form">
      <el-form-item label="项目名称" prop="projectName" class="w540">
        <el-input v-model="form.projectName" placeholder="请输入项目名称"></el-input>
      </el-form-item>
      <el-form-item label="项目角色" prop="projectWeblink" class="w540">
        <el-input v-model="form.projectRole" placeholder="请输入项目角色"></el-input>
      </el-form-item>
      <el-form-item label="项目链接" class="w540">
        <el-input v-model="form.projectWeblink" placeholder="请输入项目链接地址"></el-input>
      </el-form-item>
      <el-form-item label="项目时间" prop="benginDate" class="width100">
        <el-date-picker
          v-model="form.benginDate"
          type="month"
          value-format="yyyy/MM"
          class="w200"
          placeholder="请选择开始时间"
          popper-class="eagle-a11y-uncut"
        ></el-date-picker>
        <span class="paddL10 paddR10">至</span>
        <el-date-picker
          v-model="form.endDate"
          type="month"
          value-format="yyyy/MM"
          class="w200"
          placeholder="请选择结束时间"
          popper-class="eagle-a11y-uncut"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="项目描述" required class="w540">
        <editor
          placeholder="请输入"
          @html="editChange"
          :testcontent="form.projectDescript"
          height="400"
        ></editor>
      </el-form-item>
      <el-form-item label="项目业绩" class="w540">
        <editor
          placeholder="请输入"
          @html="editChanges"
          :testcontent="form.projectResults"
          height="400"
        ></editor>
      </el-form-item>
      <div class="buttons text_c marT50">
        <el-button type="info" plain class="w120" @click="halClose()">取消</el-button>
        <el-button type="primary" class="w120" @click="handleSave('form')">保存</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import editor from "@/components/public/editor";
export default {
  name: "project",
  components: {
    editor,
  },
  props: ["id"],
  data() {
    return {
      form: {
        projectName: "",
        projectWeblink: "",
        projectRole: "",
        benginDate: "",
        endDate: "",
        projectDescript: "",
        projectResults: "",
      },
      error: {
        projectName: [
          { required: true, message: "项目名称不能为空", trigger: "change" },
        ],
        projectRole: [
          { required: true, message: "项目角色不能为空", trigger: "change" },
        ],
        benginDate: [
          { required: true, message: "日期不能为空", trigger: "change" },
        ],
        projectDescript: [
          { required: true, message: "项目描述不可为空", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    handleSave(form) {
      let that = this;
      if(this.form.projectDescript.length < 15){
        this.$message.error("项目描述不能少于10个字");
      }
      this.$refs[form].validate(async (valid) => {
        try {
          if (valid) {
            let res = await that.$api.resumeProject(that.form);
            if (res.data.success) {
              that.$message.success(res.data.msg);
              that.halClose();
              console.log(that);
              that.$parent.$parent.disableResume();
              this.form = {};
            }
          } else {
            this.$message.error("信息填写不完整！");
            return false;
          }
        } catch (err) {
          alert(err);
        }
      });
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    editChange(val) {
      this.form.projectDescript = val;
    },
    editChanges(val) {
      this.form.projectResults = val;
    },
    halClose(val) {
      this.$emit("close", false);
    },
    async myResumeProjectDetail() {
      let id = this.id;
      try {
        let res = await this.$api.myResumeProjectDetail({ id: this.id });
        console.log(res);

        this.form = res.data.data;
      } catch (error) {
        alert(error);
      }
    },
  },
  created() {
    if (this.id) {
      this.myResumeProjectDetail();
    }
  },
};
</script>

<style scoped lang="less">
/deep/ .el-dialog__body {
  padding: 0px;
}
</style>
